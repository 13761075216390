import React, { useState } from "react";
import { connect } from "react-redux";
import { TextField } from "@material-ui/core";
import clsx from "clsx";
import * as auth from "../../store/ducks/auth.duck";
import { Formik } from "formik";
import { Link } from "react-router-dom";
import { sendEmail } from '../../crud/auth.crud';
// import Swal from 'sweetalert2';
import BetterAlerts from "../../components/BetterAlerts";
import { useTranslation } from "react-i18next";
import { toAbsoluteUrl } from "../../utils/Utils";

function ForgotPassword(props) {
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);
	const [success_list, setSuccessList] = useState([]);
	const [error_list, setErrorList] = useState([]);

	const regexEmail = /^[\w+.]+@\w+\.\w{2,}(?:\.\w{2})?$/;

	const validateForm = values => {
		let map = new Map();

		map.set('email', 'email');

		const errors = {};

		for (let [key, value] of map) {
			if (!values[key]) {
				errors[key] = t("ForgotPassword.validation.invalidEmail", { field: value });
			} else if (key === 'email' && !regexEmail.test(values[key])) {
				errors[key] = t("ForgotPassword.validation.invalidEmail");
			}
		}

		return errors;
	};

	const onSubmit = values => {
		setLoading(true);

		sendEmail(values).then(res => {
			setSuccessList([t("ApiCalls.sendEmail.success")]);
			setLoading(false);
		}).catch(() => {
			setErrorList([t("ApiCalls.sendEmail.error")]);
		});
	};

	const getInitialValues = () => {
		return {
			email: '',
		};
	}

	return (
		<div className='body-reset-password' >
			<div className='card-reset-password' >
				<Formik initialValues={getInitialValues()} validate={values => validateForm(values)} onSubmit={(values) => onSubmit(values)}>
					{({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
						<form noValidate={true} autoComplete="off" onSubmit={handleSubmit}>
							<div style={{ display: 'flex', justifyContent: 'center' }} className='row'>
								<Link to="/" className="kt-login__logo" style={{ display: 'flex', justifyContent: 'center', marginBottom: '50px' }}>
									<img alt="Logo" src={toAbsoluteUrl("/images/logo-dark.png")} style={{ maxWidth: '70%', height: 'auto' }} />
								</Link>
							</div>

							<div className="row">
								<div className='col-sm-12'>
									<center><h3 style={{ marginBottom: '25px' }} className="kt-login__title">{t("ForgotPassword.title")}</h3></center>
									<center><h4 style={{ marginBottom: '40px' }} className="kt-login__subtitle">{t("ForgotPassword.subtitle1")}<br />{t("ForgotPassword.subtitle2")}</h4></center>
								</div>

								<div style={{ marginBottom: '25px' }} className='col-sm-12'>
									<BetterAlerts errors={error_list} success={success_list} />
								</div>

								<div className="col-sm-12">
									<div className="form-group fg-line">
										<TextField name="email" label={`${t("Fields.email.title")} *`} margin="normal" variant="outlined"
											onBlur={handleBlur} onChange={handleChange} value={values.email}
											helperText={touched.email && errors.email} type='email'
											error={Boolean(touched.email && errors.email)} />
									</div>
								</div>
							</div>

							<div className="row">
								<div className="col-sm-12">
									<center>
										<button type="submit" disabled={loading} className="btn btn-success btn-bold">
											<i className={`margin-icon ${loading ? "fas fa-sync fa-spin" : "fa fa-check-square"}`}></i>{t("Buttons.confirm")}
										</button>
									</center>
								</div>
							</div>
						</form>
					)}
				</Formik>
			</div>
		</div>
	);
}

export default connect(null, auth.actions)(ForgotPassword)

