let url = "";

if (process.env.NODE_ENV === 'development') {
	url = "http://localhost:8000";
} else {
	url = "https://dashboard.denakop.com";
}

export const Constants = {
	url: url,
	baseUrl: url + "/api/web",
	// adSenseRedirectURL: 'http://localhost:8000/api/web/oauth/adsense',
	// analyicsRedirectURL: 'http://localhost:8000/api/web/oauth/analytics'
	adSenseRedirectURL: 'https://api.juicebarads.com/api/web/oauth/adsense',
	analyicsRedirectURL: 'https://api.juicebarads.com/api/web/oauth/analytics'
};
