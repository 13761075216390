import React, { Suspense, lazy, useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import i18n from "../../i18next/i18n";
import { LayoutSplashScreen } from "../../partials/layout/Context";

const DashboardPage = lazy(() =>
	import("./dashboard/DashboardPage")
);

const ExportPage = lazy(() =>
	import("./export/ExportPage")
);

const DashboardDenakopPage = lazy(() =>
	import("./dashboard/DashboardDenakopPage")
);

const AccountPage = lazy(() =>
	import("./account/AccountPage")
);

const UserPage = lazy(() =>
	import("./user/UserPage")
);

const VisitLogPage = lazy(() =>
	import('./visit-log/VisitLogPage')
);

const AdNetworkCategoryPage = lazy(() =>
	import('./ad-network-category/AdNetworkCategoryPage')
);

const OrderPage = lazy(() =>
	import('./order/OrderPage')
);

const OrderCampaignPage = lazy(() =>
	import('./order-campaign/OrderCampaignPage')
);

const AdNetworksPage = lazy(() =>
	import('./ad-networks/AdNetworksPage')
);

const AdNetworkTypePage = lazy(() =>
	import('./ad-network-type/AdNetworkTypePage')
);

const WebsitePage = lazy(() =>
	import('./website/WebsitePage')
);

const CurrencyExchangePage = lazy(() =>
	import('./currency-exchange/CurrencyExchangePage')
);

const NotificationPage = lazy(() =>
	import('./notification/NotificationPage')
);

const UserNotificationPage = lazy(() =>
	import('./user-notification/UserNotificationPage')
);

const GroupPage = lazy(() =>
	import("./group/GroupPage")
);

const ReportPage = lazy(() =>
	import("./report/ReportPage")
);

const AdNetworkReportPage = lazy(() =>
	import("./ad-network-report/AdNetworkReportPage")
);

const MaintenancePage = lazy(() =>
	import("./maintenance/MaintenancePage")
);

const ApiLogPage = lazy(() =>
	import("./api-log/ApiLogPage")
);

const FormatPage = lazy(() =>
	import("./format/FormatPage")
);

const RevenueSharePage = lazy(() =>
	import("./revenue-share/RevenueSharePage")
);

const AutoAdRequestPage = lazy(() =>
	import("./auto-ad-request/AutoAdRequestPage")
);

const AdsPage = lazy(() =>
	import("./ads/AdsPage")
);

const TagPage = lazy(() =>
	import("./tag/TagPage")
);

// const SystemLogPage = lazy(() =>
// 	import("./system-log/SystemLogPage")
// );

export default function HomePage() {
	const { user } = useSelector(
		({ auth }) => ({
		  user: auth.user,
		}),
		shallowEqual
	  );

	useEffect(() => {
		if (user?.usr_locale) {
			if (i18n.language !== user?.usr_locale) {
				i18n.changeLanguage(user?.usr_locale);
			} 
		}
	}, []);

	return (
		<Suspense fallback={<LayoutSplashScreen />}>
			<Switch>
				{<Redirect exact from="/" to="/dashboard" />}

				<Route path="/dashboard" component={DashboardDenakopPage} />
				<Route path="/account" component={AccountPage} />
				<Route path="/user" component={UserPage} />
				<Route path="/visit-log" component={VisitLogPage} />
				<Route path="/ad-network-category" component={AdNetworkCategoryPage} />
				<Route path="/api-log" component={ApiLogPage} />
				<Route path="/format" component={FormatPage} />
				<Route path="/export" component={ExportPage} />
				<Route path="/revenue-share" component={RevenueSharePage} />
				<Route path="/auto-ad-request" component={AutoAdRequestPage} />
				<Route path="/ads" component={AdsPage} />
				<Route path="/tag" component={TagPage} />
				<Route path="/orders" component={OrderPage} />
				<Route path="/order-campaign" component={OrderCampaignPage} />
				<Route path="/ad-networks" component={AdNetworksPage} />
				<Route path="/ad-network-type" component={AdNetworkTypePage} />
				<Route path="/website" component={WebsitePage} />
				<Route path="/currency-exchange" component={CurrencyExchangePage} />
				<Route path="/notifications-sent" component={NotificationPage} />
				<Route path="/my-notifications" component={UserNotificationPage} />
				<Route path="/group" component={GroupPage} />
				<Route path="/report" component={ReportPage} />
				{/* <Route path="/system-log" component={SystemLogPage} /> */}
				<Route path="/maintenance" component={MaintenancePage} />
				<Route path="/ad-network-report/new" component={AdNetworkReportPage} />

				<Redirect to="/error/error-v6" />
			</Switch>
		</Suspense>
	);
}
