import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import store, { persistor } from "./app/store/store";
import axios from "axios";
import App from "./App";
import "./index.scss";
// import "./sass/style.react.rtl.css"; // RTL version
import "socicon/css/socicon.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import './app/i18next/i18n';
import { setupAxios } from "./app/utils/Utils";


const { PUBLIC_URL } = process.env;

// Interceptador do axios para verificar
// se possui token e configurar header.
setupAxios(axios, store);


ReactDOM.render(
  <App store={store} persistor={persistor} basename={PUBLIC_URL} />,
  document.getElementById("root")
);
