import React, { useEffect, useState } from 'react';
import './custom-dropdown.scss';

export const CustomDropdown = ({ isOpen, uniqueIdForDropdown, renderTopSection, renderBottomDropdownContent }) => {
	const [itemsListContainerHeight, setItemsListContainerHeight] = useState(0);

	useEffect(() => {
		const itemList = document.getElementById(uniqueIdForDropdown);

		if (itemList?.clientHeight && itemsListContainerHeight !== itemList?.clientHeight) {
			setItemsListContainerHeight(itemList?.clientHeight);
		}
	}, [itemsListContainerHeight, setItemsListContainerHeight]);

	const renderCardBottomSection = () => (
		<div
			className={'container-categoria-bottom-section'}
			style={{ height: isOpen ? itemsListContainerHeight : 0 }}>
			<div id={uniqueIdForDropdown} className={'categoria-item-list-container'}>
				{renderBottomDropdownContent()}
			</div>
		</div>
	);

	const renderCustomIcon = () => <i className={`fa fa-angle-down toggle-arrow`} aria-hidden="true" />;

	return (
		<div className={`custom-dropdown-container ${isOpen ? 'card-visible' : ''}`}>
			{renderTopSection(renderCustomIcon)}

			{renderCardBottomSection()}
		</div>
	);
}