import React from 'react';
import { Select } from '@material-ui/core';
import './custom-select.scss';

export const CustomSelect = (props) => {

	return (
		<Select
			MenuProps={{
				anchorOrigin: {
					vertical: "bottom",
					horizontal: "left"
				},
				transformOrigin: {
					vertical: "top",
					horizontal: "left"
				},
				getContentAnchorEl: null
			}}
			disableUnderline
			className={`custom-select-container custom-${props.variant} ${props.variant}-${props.textColor} ${props.customClassName}`}
			value={props.value}
			onChange={(event) => props.onChange(event)}
		>
			{props.children}
		</Select>
	);
}