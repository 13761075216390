
import axios from "axios";
import { Constants } from '../utils/Constants';

export function getDataTable(events) {
	return axios.post(`${Constants.baseUrl}/user/list`, events);
}

export function get(id) {
	return axios.get(`${Constants.baseUrl}/user/${id}`);
}

export function simpleList() {
	return axios.get(`${Constants.baseUrl}/user/simpleList`);
}

export function simpleListByGroup(groupIds) {
	return axios.get(`${Constants.baseUrl}/user/simpleList/group?groupIds[]=${groupIds.join("&groupIds[]=")}`);
}

export function update(id, data) {
	return axios.post(`${Constants.baseUrl}/user/${id}`, data);
}

export function store(data) {
	return axios.post(`${Constants.baseUrl}/user`, data);
}

export function destroy(id) {
	return axios.delete(`${Constants.baseUrl}/user/${id}`);
}

export function updateUserLocale(data) {
	return axios.post(`${Constants.baseUrl}/user/updateUserLocale`, data);
}

export function getUserAccountManager() {
	return axios.get(`${Constants.baseUrl}/user/accountManager`);
}