import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import Fade from '@mui/material/Fade';
import './custom-menu.scss';

export const CustomMenu = (props) => {
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<div className={`custom-menu-container ${open ? 'open' : ''}`}>
			<Button
				id="fade-button"
				aria-controls={open ? 'fade-menu' : undefined}
				aria-haspopup="true"
				aria-expanded={open ? 'true' : undefined}
				onClick={handleClick}
				// endIcon={<i className='fas fa-caret-down' />}
			>
				{props.renderButtonContent()}
			</Button>
			{/* <Menu
				id="fade-menu"
				MenuListProps={{
					'aria-labelledby': 'fade-button',
				}}
				PaperProps={{
					style: {
						width: props.menuCustomWidth ?? 'unset',
					},
				}}
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				TransitionComponent={Fade}
			>
				{props.children}
			</Menu> */}
		</div>
	);
}