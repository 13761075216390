import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { en_US } from "./locales/en_US/en_US";
import { pt_BR } from './locales/pt_BR/pt_BR';
import { es_ES } from './locales/es_ES/es_ES';
import { it_IT } from './locales/it_IT/it_IT';

const resources = {
	en_US: {
		translation: en_US
	},
	pt_BR: {
		translation: pt_BR
	},
	es_ES: {
		translation: es_ES
	},
	it_IT: {
		translation: it_IT
	}	
};

i18n
	// detect user language
	// learn more: https://github.com/i18next/i18next-browser-languageDetector
	.use(LanguageDetector)
	// pass the i18n instance to react-i18next.
	.use(initReactI18next)
	// init i18next
	// for all options read: https://www.i18next.com/overview/configuration-options
	.init({
		debug: true,
		fallbackLng: ['en_US'],
		interpolation: {
			escapeValue: false, // not needed for react as it escapes by default
		},
		resources,
		detection: {
			convertDetectedLanguage: (lng) => lng.replace('-', '_')
		}
	});

export default i18n;