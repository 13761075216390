import { createFormikValidation } from '../../../utils/Utils';

export const validateForm = (values, isEdit, translate) => {
	let map = new Map();

	map.set('email', translate("Fields.email.title"));
	map.set('usr_id_group', translate("Fields.usr_id_group.title"));
	map.set('usr_name', translate("Fields.usr_name.title"));
	map.set('usr_status', translate("Fields.usr_status.title"));
	map.set('usr_product', translate("Fields.usr_product.title"));

	if (!isEdit) map.set('password', translate("Fields.password.title"));

	return createFormikValidation(map, values, translate);
};

export const indexPage = () => {
	return "/user";
};