import Swal from 'sweetalert2';
import { actionTypes } from '../../app/store/ducks/auth.duck';

export function removeCSSClass(ele, cls) {
	const reg = new RegExp("(\\s|^)" + cls + "(\\s|$)");
	ele.className = ele.className.replace(reg, " ");
}

export function addCSSClass(ele, cls) {
	ele.classList.add(cls);
}

/*  removeStorage: removes a key from localStorage and its sibling expiracy key
	params:
		key <string>     : localStorage key to remove
	returns:
		<boolean> : telling if operation succeeded
 */
export function removeStorage(key) {
	try {
		localStorage.setItem(key, "");
		localStorage.setItem(key + "_expiresIn", "");
	} catch (e) {
		// console.log(
		// 	"removeStorage: Error removing key [" +
		// 	key +
		// 	"] from localStorage: " +
		// 	JSON.stringify(e)
		// );
		return false;
	}
	return true;
}

/*  getStorage: retrieves a key from localStorage previously set with setStorage().
	params:
		key <string> : localStorage key
	returns:
		<string> : value of localStorage key
		null : in case of expired key or failure
 */
export function getStorage(key) {
	const now = Date.now(); //epoch time, lets deal only with integer
	// set expiration for storage
	let expiresIn = localStorage.getItem(key + "_expiresIn");
	if (expiresIn === undefined || expiresIn === null) {
		expiresIn = 0;
	}

	expiresIn = Math.abs(expiresIn);
	if (expiresIn < now) {
		// Expired
		removeStorage(key);
		return null;
	} else {
		try {
			const value = localStorage.getItem(key);
			return value;
		} catch (e) {
			// console.log(
			// 	"getStorage: Error reading key [" +
			// 	key +
			// 	"] from localStorage: " +
			// 	JSON.stringify(e)
			// );
			return null;
		}
	}
}
/*  setStorage: writes a key into localStorage setting a expire time
	params:
		key <string>     : localStorage key
		value <string>   : localStorage value
		expires <number> : number of seconds from now to expire the key
	returns:
		<boolean> : telling if operation succeeded
 */
export function setStorage(key, value, expires) {
	if (expires === undefined || expires === null) {
		expires = 24 * 60 * 60; // default: seconds for 1 day
	}

	const now = Date.now(); //millisecs since epoch time, lets deal only with integer
	const schedule = now + expires * 1000;
	try {
		localStorage.setItem(key, value);
		localStorage.setItem(key + "_expiresIn", schedule);
	} catch (e) {
		// console.log(
		// 	"setStorage: Error setting key [" +
		// 	key +
		// 	"] in localStorage: " +
		// 	JSON.stringify(e)
		// );
		return false;
	}
	return true;
}

export function isShowMenu(title, permissions) {
	let isShow = false;

	switch (title) {
		case 'dashboard':
			permissions.map((obj, i) => {
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\DashboardController' && obj.grp_method === 'getErrorLog') isShow = true;
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\DashboardController' && obj.grp_method === 'getTotalizers') isShow = true;
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\DashboardController' && obj.grp_method === 'getCurrencyExchange') isShow = true;
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\DashboardController' && obj.grp_method === 'getCharts') isShow = true;
			});
		case 'reports':
			permissions.map((obj, i) => {
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\ReportController' && obj.grp_method === 'getReportDenakopAllNecessaryLists') isShow = true;
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\ReportController' && obj.grp_method === 'getReport01') isShow = true;
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\ReportController' && obj.grp_method === 'getReport02') isShow = true;
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\ReportController' && obj.grp_method === 'getReport03') isShow = true;
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\ReportController' && obj.grp_method === 'getReport04') isShow = true;
			});
		case 'reportsDenakop':
			permissions.map((obj, i) => {
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\ReportController' && obj.grp_method === 'getReportDenakopAllNecessaryLists') isShow = true;
			});
		case 'reportsData':
			permissions.map((obj, i) => {
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\ReportController' && obj.grp_method === 'getReport01') isShow = true;
			});
		case 'reportsOrders':
			permissions.map((obj, i) => {
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\ReportController' && obj.grp_method === 'getReport02') isShow = true;
			});
		case 'reportsCurrency':
			permissions.map((obj, i) => {
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\ReportController' && obj.grp_method === 'getReport03') isShow = true;
			});
		case 'currencyExchange':
			permissions.map((obj, i) => {
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\CurrencyExchangeController' && obj.grp_method === 'list') isShow = true;
			});
		case 'reportsDataPerWebsite':
			permissions.map((obj, i) => {
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\ReportController' && obj.grp_method === 'getReport04') isShow = true;
			});
		case 'reportsFinancial':
			permissions.map((obj, i) => {
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\ReportController' && obj.grp_method === 'getReport05') isShow = true;
			});
		case 'publishers':
			permissions.map((obj, i) => {
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\AccountController' && obj.grp_method === 'list') isShow = true;
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\UserController' && obj.grp_method === 'list') isShow = true;
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\UserAccessLogController' && obj.grp_method === 'list') isShow = true;
			});
		case 'publishersAccounts':
			permissions.map((obj, i) => {
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\AccountController' && obj.grp_method === 'list') isShow = true;
			});
		case 'publishersUsers':
			permissions.map((obj, i) => {
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\UserController' && obj.grp_method === 'list') isShow = true;
			});
		case 'publishersVisitLogs':
			permissions.map((obj, i) => {
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\UserAccessLogController' && obj.grp_method === 'list') isShow = true;
			});
		// todo arrumar permissions
		case 'formats':
			permissions.map((obj, i) => {
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\AutoAdController' && obj.grp_method === 'getAllByWebsite') isShow = true;
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\AutoAdController' && obj.grp_method === 'listForAutoAdRequest') isShow = true;
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\AccountController' && obj.grp_method === 'getForAdsTxt') isShow = true;
			});
		case 'formatsFormats':
			permissions.map((obj, i) => {
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\AutoAdController' && obj.grp_method === 'getAllByWebsite') isShow = true;
			});
		case 'formatsAutoAdRequests':
			permissions.map((obj, i) => {
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\AutoAdController' && obj.grp_method === 'listForAutoAdRequest') isShow = true;
			});
		case 'formatsAds':
			permissions.map((obj, i) => {
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\AccountController' && obj.grp_method === 'getForAdsTxt') isShow = true;
			});
		case 'formatsTags':
			permissions.map((obj, i) => {
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\AccountController' && obj.grp_method === 'getForAdsTxt') isShow = true;
			});
		case 'adNetworks':
			permissions.map((obj, i) => {
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\AdNetworkCategoryController' && obj.grp_method === 'list') isShow = true;
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\AdNetworkController' && obj.grp_method === 'list') isShow = true;
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\AdNetworkTypeController' && obj.grp_method === 'list') isShow = true;
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\OrderController' && obj.grp_method === 'list') isShow = true;
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\WebsiteController' && obj.grp_method === 'list') isShow = true;
			});
		case 'adNetworksTecnologies':
			permissions.map((obj, i) => {
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\AdNetworkCategoryController' && obj.grp_method === 'list') isShow = true;
			});
		case 'adNetworksOrdersCampaigns':
			permissions.map((obj, i) => {
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\OrderCampaignController' && obj.grp_method === 'list') isShow = true;
			});
		case 'adNetworksOrders':
			permissions.map((obj, i) => {
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\OrderController' && obj.grp_method === 'list') isShow = true;
			});
		case 'adNetworksAdNetworks':
			permissions.map((obj, i) => {
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\AdNetworkController' && obj.grp_method === 'list') isShow = true;
			});
		case 'adNetworksType':
			permissions.map((obj, i) => {
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\AdNetworkTypeController' && obj.grp_method === 'list') isShow = true;
			});
		case 'adNetworksWebsite':
			permissions.map((obj, i) => {
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\WebsiteController' && obj.grp_method === 'list') isShow = true;
			});
		case 'adNetworksRevenueShare':
			permissions.map((obj, i) => {
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\WebsiteController' && obj.grp_method === 'list') isShow = true;
			});
		case 'adNetworksApiLogs':
			permissions.map((obj, i) => {
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\ApiLogController' && obj.grp_method === 'list') isShow = true;
			});
		case 'sentNotifications':
			permissions.map((obj, i) => {
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\NotificationController' && obj.grp_method === 'list') isShow = true;
			});
		case 'myNotifications':
			permissions.map((obj, i) => {
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\UserNotificationController' && obj.grp_method === 'list') isShow = true;
			});
		case 'addData':
			permissions.map((obj, i) => {
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\AdNetworkReportController' && obj.grp_method === 'store') isShow = true;
			});
		case 'accessGroups':
			permissions.map((obj, i) => {
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\GroupController' && obj.grp_method === 'list') isShow = true;
			});
		case 'systemLogs':
			permissions.map((obj, i) => {
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\AdNetworkCategoryController' && obj.grp_method === 'list') isShow = true;
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\AdNetworkController' && obj.grp_method === 'list') isShow = true;
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\AdNetworkTypeController' && obj.grp_method === 'list') isShow = true;
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\OrderController' && obj.grp_method === 'list') isShow = true;
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\WebsiteController' && obj.grp_method === 'list') isShow = true;
			});
		case 'systemLogsLogin':
			permissions.map((obj, i) => {
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\AdNetworkCategoryController' && obj.grp_method === 'list') isShow = true;
			});
		case 'systemLogsAccounts':
			permissions.map((obj, i) => {
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\OrderCampaignController' && obj.grp_method === 'list') isShow = true;
			});
		case 'systemLogsFormats':
			permissions.map((obj, i) => {
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\OrderController' && obj.grp_method === 'list') isShow = true;
			});
		case 'systemLogsUsers':
			permissions.map((obj, i) => {
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\AdNetworkController' && obj.grp_method === 'list') isShow = true;
			});
		case 'systemLogsWebsites':
			permissions.map((obj, i) => {
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\AdNetworkTypeController' && obj.grp_method === 'list') isShow = true;
			});
		case 'systemLogsAccessGroups':
			permissions.map((obj, i) => {
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\WebsiteController' && obj.grp_method === 'list') isShow = true;
			});
		case 'systemLogsAccountManagers':
			permissions.map((obj, i) => {
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\ApiLogController' && obj.grp_method === 'list') isShow = true;
			});
		case 'maintenance':
			permissions.map((obj, i) => {
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\UserMaintenanceController' && obj.grp_method === 'list') isShow = true;
			});
	}

	return isShow;
}