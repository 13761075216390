import moment from "moment";
import React from "react";
import "./footer.scss";
import { toAbsoluteUrl } from "../../../utils/Utils";
import { useTranslation } from "react-i18next";
import { Link } from "@material-ui/core";

export const Footer = () => {
	const { t, i18n } = useTranslation();

	return (
		<div className="footer">
			<div className="footer-without-max-width-container">
				<img className="logo" src={toAbsoluteUrl("/images/logo-dark.svg")} width={137} height={32} alt="Logo" />

				<div className="links-and-copyright">
					<div>
						<Link href={`https://denakop.com/${i18n.language.split('_')[0]}/terms-and-privacy`} target="_blank" rel="noopener noreferrer">{t('Footer.privacyPolicy')}</Link>

						<Link href={`https://denakop.com/${i18n.language.split('_')[0]}/terms-and-privacy`} target="_blank" rel="noopener noreferrer">{t('Footer.termsOfUse')}</Link>
					</div>

					<span>
						Denakop @ {moment().format('YYYY')}
					</span>
				</div>
			</div>
		</div>
	);
}