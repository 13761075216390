// pages
import AuthPage from './pages/AuthPage.json';
import Login from './pages/Login.json';
import ForgotPassword from './pages/ForgotPassword.json';
import ResetPassword from './pages/ResetPassword.json';
import OauthSuccess from './pages/OauthSuccess.json';
import DashboardPage from './pages/DashboardPage.json';
import ReportDenakop from './pages/ReportDenakop.json';
import Report01 from './pages/Report01.json';
import Report02 from './pages/Report02.json';
import Report03 from './pages/Report03.json';
import Report04 from './pages/Report04.json';
import Report05 from './pages/Report05.json';
import Account from './pages/Account.json';
import User from './pages/User.json';
import VisitLog from './pages/VisitLog.json';
import Format from './pages/Format.json';
import RevenueShare from './pages/RevenueShare.json';
import AutoAdRequest from './pages/AutoAdRequest.json';
import Ads from './pages/Ads.json';
import Tag from './pages/Tag.json'
import AdNetworkCategory from './pages/AdNetworkCategory.json';
import SystemLogLogin from './pages/SystemLogLogin.json';
import SystemLogAccount from './pages/SystemLogAccount.json';
import OrderCampaign from './pages/OrderCampaign.json';
import Order from './pages/Order.json';
import AdNetworkType from './pages/AdNetworkType.json';
import AdNetwork from './pages/AdNetwork.json';
import Website from './pages/Website.json';
import ApiLog from './pages/ApiLog.json';
import CurrencyExchange from './pages/CurrencyExchange.json';
import Notification from './pages/Notification.json';
import UserNotification from './pages/UserNotification.json';
import AdNetworkReport from './pages/AdNetworkReport.json';
import Group from './pages/Group.json';
import Maintenance from './pages/Maintenance.json';

// global
import Global from './common/Global.json';
import Buttons from './common/Buttons.json';
import Fields from './common/Fields.json';
import ApiCalls from './common/ApiCalls.json';
import MenuConfig from './common/MenuConfig.json';
import Types from './common/Types.json';
import Permissions from './common/Permissions.json';

// components
import UserWidget from './components/UserWidget.json';
import Header from './components/Header.json';
import ChatBalloon from './components/ChatBalloon.json';
import Footer from './components/Footer.json';

export const it_IT = {
	// pages
	AuthPage,
	Login,
	ForgotPassword,
	ResetPassword,
	OauthSuccess,
	DashboardPage,

	ReportDenakop,
	Report01,
	Report02,
	Report03,
	Report04,
	Report05,
	Account,
	User,
	VisitLog,
	Format,
	RevenueShare,
	AutoAdRequest,
	Ads,
	Tag,
	AdNetworkCategory,
	SystemLogLogin,
	SystemLogAccount,
	OrderCampaign,
	Order,
	AdNetworkType,
	AdNetwork,
	Website,
	ApiLog,
	CurrencyExchange,
	Notification,
	UserNotification,
	AdNetworkReport,
	Group,
	Maintenance,

	// common
	Global,
	MenuConfig,
	Buttons,
	Fields,
	ApiCalls,
	Types,
	Permissions,

	// components
	UserWidget,
	Header,
	ChatBalloon,
	Footer
};