import React, { useState } from "react";
import { Formik } from "formik";
import { connect } from "react-redux";
import { TextField } from "@material-ui/core";
import clsx from "clsx";
import * as auth from "../../store/ducks/auth.duck";
import { login } from "../../crud/auth.crud";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { setStorage } from "../../utils/Utils";

function Login(props) {
	const { t } = useTranslation();
	const [loading, setLoading] = useState(false);
	const [loadingButtonStyle, setLoadingButtonStyle] = useState({ paddingRight: "2.5rem" });

	const enableLoading = () => {
		setLoading(true);
		setLoadingButtonStyle({ paddingRight: "3.5rem" });
	};

	const disableLoading = () => {
		setLoading(false);
		setLoadingButtonStyle({ paddingRight: "2.5rem" });
	};

	return (
		<>
			<div className="kt-login__head">
			</div>

			<div className="kt-login__body">
				<div className="kt-login__form">
					<div className="kt-login__title">
						<h3>{t("Login.title")}</h3>
					</div>

					<Formik initialValues={{ email: "", password: "" }}
						validate={values => {
							const errors = {};

							if (!values.email) {
								errors.email = t("Login.validation.emailRequired");
							} else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
								errors.email = t("Login.validation.emailIncorrect");
							}

							if (!values.password) {
								errors.password = t("Login.validation.passwordRequired");
							}

							return errors;
						}} onSubmit={(values, { setStatus, setSubmitting }) => {
							enableLoading();

							login(values.email, values.password).then(res => {
								disableLoading();

								setStorage('authToken', res.data.token, null);
								res.data.currency = 'BRL';
								props.login(res.data);
							}).catch(() => {
								disableLoading();

								setSubmitting(false);

								setStatus(t("Login.validation.emailOrPasswordIncorrect"));
							});
						}}>

						{({ values, status, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
							<form noValidate={true} autoComplete="off" className="kt-form" onSubmit={handleSubmit}>
								{status ? (
									<div role="alert" className="alert alert-danger">
										<div className="alert-text">{status}</div>
									</div>
								) : null}

								<div className="form-group">
									<TextField type="email" label={t("Login.customFields.yourEmail")} margin="normal" className="kt-width-full" name="email"
										onBlur={handleBlur} onChange={handleChange} value={values.email} helperText={touched.email && errors.email}
										error={Boolean(touched.email && errors.email)} />
								</div>

								<div className="form-group">
									<TextField type="password" margin="normal" label={t("Login.customFields.yourPassword")} className="kt-width-full" name="password"
										onBlur={handleBlur} onChange={handleChange} value={values.password} helperText={touched.password && errors.password}
										error={Boolean(touched.password && errors.password)} />
								</div>

								<div className="kt-login__actions">
									<Link rel="noopener noreferrer" target="_blank" to="/auth/forgot-password" className="kt-link kt-login__link-forgot">
										<a>{t("Buttons.forgotPassword")}</a>
									</Link>

									<button id="kt_login_signin_submit" type="submit" disabled={isSubmitting} className={`btn btn-primary btn-elevate kt-login__btn-primary 
                    						${clsx({ "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading })}`}
										style={loadingButtonStyle}>{`${t("Buttons.enter")}!`}</button>
								</div>
							</form>
						)}
					</Formik>

				</div>
			</div>
		</>
	);
}

export default connect(null, auth.actions)(Login);

