export const routes = [
	{
		title: "dashboard",
		root: true,
		page: "dashboard",
	},
	{
		title: "reports",
		root: true,
		submenu: [
			{
				title: "reportsDenakop",
				page: "report/reportDenakop"
			},
			{
				title: "reportsData",
				page: "report/report01"
			},
			{
				title: "reportsOrders",
				page: "report/report02"
			},
			{
				title: "reportsCurrency",
				page: "report/report03"
			},
			{
				title: "reportsDataPerWebsite",
				page: "report/report04"
			},
			{
				title: "reportsFinancial",
				page: "report/report05"
			},

		]
	},
	{
		title: "publishers",
		root: true,
		submenu: [
			{
				title: "publishersAccounts",
				page: "account"
			},
			{
				title: "publishersUsers",
				page: "user"
			},
			{
				title: "publishersVisitLogs",
				page: "visit-log"
			},
		]
	},
	{
		title: "formats",
		root: true,
		submenu: [
			{
				title: "formatsFormats",
				page: "format"
			},
			{
				title: "formatsAutoAdRequests",
				page: "auto-ad-request"
			},
			{
				title: "formatsAds",
				page: "ads"
			},
			{
				title: "formatsTags",
				page: "tag"
			},
		]
	},
	{
		title: "adNetworks",
		root: true,
		submenu: [
			{
				title: "adNetworksTecnologies",
				page: "ad-network-category"
			},
			{
				title: "adNetworksOrdersCampaigns",
				page: "order-campaign"
			},
			{
				title: "adNetworksOrders",
				page: "orders"
			},
			{
				title: "adNetworksAdNetworks",
				page: "ad-networks"
			},
			{
				title: "adNetworksType",
				page: "ad-network-type"
			},
			{
				title: "adNetworksWebsite",
				page: "website"
			},
			{
				title: "adNetworksRevenueShare",
				page: "revenue-share"
			},
			{
				title: "adNetworksApiLogs",
				page: "api-log"
			},
		]
	},
	// {
	// 	title: "systemLogs",
	// 	root: true,

	// // 	submenu: [
	// 		{
	// 			title: "systemLogsLogin",
	//
	// 			page: "system-log/logLogin"
	// 		},
	// 		{
	// 			title: "systemLogsAccounts",
	//
	// 			page: "system-log/logAccount"
	// 		},
	// 		{
	// 			title: "systemLogsFormats",
	//
	// 			page: "system-log/logFormat"
	// 		},
	// 		{
	// 			title: "systemLogsUsers",
	//
	// 			page: "system-log/logUser"
	// 		},
	// 		{
	// 			title: "systemLogsWebsites",
	//
	// 			page: "system-log/logWebsite"
	// 		},
	// 		{
	// 			title: "systemLogsAccessGroups",
	//
	// 			page: "system-log/logAccessGroup"
	// 		},
	// 		{
	// 			title: "systemLogsAccountManagers",
	//
	// 			page: "system-log/logAccountManager"
	// 		},
	// 	]
	// },
	{
		title: "currencyExchange",
		root: true,
		page: "currency-exchange",
	},
	{
		title: "sentNotifications",
		root: true,
		page: "notifications-sent",
	},
	{
		title: "myNotifications",
		root: true,
		page: "my-notifications",
	},
	{
		title: "addData",
		root: true,
		page: "ad-network-report/new",
	},
	{
		title: "accessGroups",
		root: true,
		page: "group",
	},
	{
		title: "maintenance",
		root: true,
		page: "maintenance",
	},
	{
		title: "export",
		root: true,
		page: "export",
	},
]


export function needsToShowRoute(title, permissions) {
	let isShow = false;

	if (!permissions || permissions.length === 0) {
		return false;
	}

	switch (title) {
		case 'dashboard':
			permissions.forEach((obj, i) => {
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\DashboardController' && obj.grp_method === 'getErrorLog') isShow = true;
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\DashboardController' && obj.grp_method === 'getTotalizers') isShow = true;
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\DashboardController' && obj.grp_method === 'getCurrencyExchange') isShow = true;
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\DashboardController' && obj.grp_method === 'getCharts') isShow = true;
			});
			break;

		case 'reports':
			permissions.forEach((obj, i) => {
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\ReportController' && obj.grp_method === 'getAllNecessaryLists') isShow = true;
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\ReportController' && obj.grp_method === 'getReport01') isShow = true;
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\ReportController' && obj.grp_method === 'getReport02') isShow = true;
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\ReportController' && obj.grp_method === 'getReport03') isShow = true;
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\ReportController' && obj.grp_method === 'getReport04') isShow = true;
			});
			break;

		case 'reportsDenakop':
			permissions.forEach((obj, i) => {
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\ReportDenakopController' && obj.grp_method === 'getAllNecessaryLists') isShow = true;
			});
			break;

		case 'reportsData':
			permissions.forEach((obj, i) => {
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\ReportController' && obj.grp_method === 'getReport01') isShow = true;
			});
			break;

		case 'reportsOrders':
			permissions.forEach((obj, i) => {
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\ReportController' && obj.grp_method === 'getReport02') isShow = true;
			});
			break;

		case 'reportsCurrency':
			permissions.forEach((obj, i) => {
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\ReportController' && obj.grp_method === 'getReport03') isShow = true;
			});
			break;

		case 'currencyExchange':
			permissions.forEach((obj, i) => {
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\CurrencyExchangeController' && obj.grp_method === 'list') isShow = true;
			});
			break;

		case 'reportsDataPerWebsite':
			permissions.forEach((obj, i) => {
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\ReportController' && obj.grp_method === 'getReport04') isShow = true;
			});
			break;

		case 'reportsFinancial':
			permissions.forEach((obj, i) => {
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\ReportController' && obj.grp_method === 'getReport05') isShow = true;
			});
			break;

		case 'publishers':
			permissions.forEach((obj, i) => {
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\AccountController' && obj.grp_method === 'list') isShow = true;
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\UserController' && obj.grp_method === 'list') isShow = true;
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\UserAccessLogController' && obj.grp_method === 'list') isShow = true;
			});
			break;

		case 'publishersAccounts':
			permissions.forEach((obj, i) => {
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\AccountController' && obj.grp_method === 'list') isShow = true;
			});
			break;

		case 'publishersUsers':
			permissions.forEach((obj, i) => {
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\UserController' && obj.grp_method === 'list') isShow = true;
			});
			break;

		case 'publishersVisitLogs':
			permissions.forEach((obj, i) => {
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\UserAccessLogController' && obj.grp_method === 'list') isShow = true;
			});
			break;

		case 'formats':
			permissions.forEach((obj, i) => {
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\AutoAdController' && obj.grp_method === 'getAllByWebsite') isShow = true;
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\AutoAdController' && obj.grp_method === 'listForAutoAdRequest') isShow = true;
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\AccountController' && obj.grp_method === 'getForAdsTxt') isShow = true;
			});
			break;

		case 'formatsFormats':
			permissions.forEach((obj, i) => {
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\AutoAdController' && obj.grp_method === 'getAllByWebsite') isShow = true;
			});
			break;

		case 'formatsAutoAdRequests':
			permissions.forEach((obj, i) => {
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\AutoAdController' && obj.grp_method === 'listForAutoAdRequest') isShow = true;
			});
			break;

		case 'formatsAds':
			permissions.forEach((obj, i) => {
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\AccountController' && obj.grp_method === 'getForAdsTxt') isShow = true;
			});
			break;

		case 'formatsTags':
			permissions.forEach((obj, i) => {
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\AccountController' && obj.grp_method === 'getForAdsTxt') isShow = true;
			});
			break;

		case 'adNetworks':
			permissions.forEach((obj, i) => {
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\AdNetworkCategoryController' && obj.grp_method === 'list') isShow = true;
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\AdNetworkController' && obj.grp_method === 'list') isShow = true;
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\AdNetworkTypeController' && obj.grp_method === 'list') isShow = true;
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\OrderController' && obj.grp_method === 'list') isShow = true;
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\WebsiteController' && obj.grp_method === 'list') isShow = true;
			});
			break;

		case 'adNetworksTecnologies':
			permissions.forEach((obj, i) => {
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\AdNetworkCategoryController' && obj.grp_method === 'list') isShow = true;
			});
			break;

		case 'adNetworksOrdersCampaigns':
			permissions.forEach((obj, i) => {
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\OrderCampaignController' && obj.grp_method === 'list') isShow = true;
			});
			break;

		case 'adNetworksOrders':
			permissions.forEach((obj, i) => {
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\OrderController' && obj.grp_method === 'list') isShow = true;
			});
			break;

		case 'adNetworksAdNetworks':
			permissions.forEach((obj, i) => {
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\AdNetworkController' && obj.grp_method === 'list') isShow = true;
			});
			break;

		case 'adNetworksType':
			permissions.forEach((obj, i) => {
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\AdNetworkTypeController' && obj.grp_method === 'list') isShow = true;
			});
			break;

		case 'adNetworksWebsite':
			permissions.forEach((obj, i) => {
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\WebsiteController' && obj.grp_method === 'list') isShow = true;
			});
			break;

		case 'adNetworksRevenueShare':
			permissions.forEach((obj, i) => {
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\WebsiteController' && obj.grp_method === 'list') isShow = true;
			});
			break;

		case 'adNetworksApiLogs':
			permissions.forEach((obj, i) => {
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\ApiLogController' && obj.grp_method === 'list') isShow = true;
			});
			break;

		case 'sentNotifications':
			permissions.forEach((obj, i) => {
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\NotificationController' && obj.grp_method === 'list') isShow = true;
			});
			break;

		case 'myNotifications':
			permissions.forEach((obj, i) => {
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\UserNotificationController' && obj.grp_method === 'list') isShow = true;
			});
			break;

		case 'addData':
			permissions.forEach((obj, i) => {
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\AdNetworkReportController' && obj.grp_method === 'store') isShow = true;
			});
			break;

		case 'accessGroups':
			permissions.forEach((obj, i) => {
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\GroupController' && obj.grp_method === 'list') isShow = true;
			});
			break;

		case 'systemLogs':
			permissions.forEach((obj, i) => {
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\AdNetworkCategoryController' && obj.grp_method === 'list') isShow = true;
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\AdNetworkController' && obj.grp_method === 'list') isShow = true;
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\AdNetworkTypeController' && obj.grp_method === 'list') isShow = true;
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\OrderController' && obj.grp_method === 'list') isShow = true;
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\WebsiteController' && obj.grp_method === 'list') isShow = true;
			});
			break;

		case 'systemLogsLogin':
			permissions.forEach((obj, i) => {
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\AdNetworkCategoryController' && obj.grp_method === 'list') isShow = true;
			});
			break;

		case 'systemLogsAccounts':
			permissions.forEach((obj, i) => {
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\OrderCampaignController' && obj.grp_method === 'list') isShow = true;
			});
			break;

		case 'systemLogsFormats':
			permissions.forEach((obj, i) => {
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\OrderController' && obj.grp_method === 'list') isShow = true;
			});
			break;

		case 'systemLogsUsers':
			permissions.forEach((obj, i) => {
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\AdNetworkController' && obj.grp_method === 'list') isShow = true;
			});
			break;

		case 'systemLogsWebsites':
			permissions.forEach((obj, i) => {
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\AdNetworkTypeController' && obj.grp_method === 'list') isShow = true;
			});
			break;

		case 'systemLogsAccessGroups':
			permissions.forEach((obj, i) => {
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\WebsiteController' && obj.grp_method === 'list') isShow = true;
			});
			break;

		case 'systemLogsAccountManagers':
			permissions.forEach((obj, i) => {
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\ApiLogController' && obj.grp_method === 'list') isShow = true;
			});
			break;

		case 'maintenance':
			permissions.forEach((obj, i) => {
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\UserMaintenanceController' && obj.grp_method === 'list') isShow = true;
			});
			break;
		case 'export':
			permissions.forEach((obj, i) => {
				if (obj.grp_controller === 'App\\Http\\Controllers\\Web\\UserMaintenanceController' && obj.grp_method === 'list') isShow = true;
			});

		default:
			break;

	}

	return isShow;
}