import React, { useState } from "react";
import "./header.scss";
import { Link } from "react-router-dom";
import { NavLink, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from "react-i18next";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { updateUserLocale } from "../../../crud/user.crud";
import { actionTypes } from "../../../store/ducks/auth.duck";
import Swal from "sweetalert2";
import { getUserLocaleTypes, toAbsoluteUrl } from "../../../utils/Utils";
import { CustomSelect } from "../custom-select/CustomSelect";
import { CustomMenu } from "../custom-menu/CustomMenu";
import { indexPage } from "../../../pages/home/user/UserUtils";

export const Header = (props) => {
	const { t, i18n } = useTranslation();
	const dispatch = useDispatch();
	const  history = useHistory();
	const { user } = useSelector(
		({ auth }) => ({
			user: auth.user
		}),
		shallowEqual
	);

	// esconde menus para acordo certo e consumidor positivo
	const isUsersAcOrCp = user.id === 201 || user.id === 200;

	const isUserOliberal = user.id === 198;


	const changeLocale = (locale) => {
		if (locale === i18n.language) {
			return;
		}

		i18n.changeLanguage(locale, (err, t) => {
			if (err) Swal.fire(t('Global.ops'), t('Global.translationChangeFailed'), 'error');

			dispatch({ type: actionTypes.ChangeUserLocale, payload: locale });

			updateUserLocale({ usr_locale: locale })
				.catch((res) => {
					// better without Swal, the user will only know when logout / login.
					return;
				})
				.then((res) => {
					if (res.status === 200) {
						// better without Swal, the user already received an instant feedback changing.
						return;
					};
				});
		});
	}

	const renderButtonContent = () => {

		return (
			<>
				<div className="user-button-menu" onClick={() => history.push(`/logout`)}>
					<span className="user-button-menu-name">
						{t("Buttons.logout")}
						{/* {user?.usr_name ?? t('Fields.notAssigned')} */}
					</span>

					{/* <span className="user-button-menu-email">
						{user?.email ?? t('Fields.notAssigned')}
					</span> */}
				</div>

				{/* <div className="user-button-menu-mobile">
					<img alt="user-profile-photo" src={user?.image?.url ?? toAbsoluteUrl("/images/no-profile.png")} />
				</div> */}
			</>
		);
	}

	return (
		<div className="header">
			<div className="header-without-max-width-container">
				<div className="menu-logo-and-nav">
					<img onClick={() => props.setIsSidebarOpen(true)} src={toAbsoluteUrl(`/images/icon-menu.svg`)} className={`menu`} alt="Menu" />

					<div className="logo">
						<Link to="/">
							<img src={toAbsoluteUrl("/images/logo-dark.svg")} className="logo-desktop" alt="Logo" />
							<img src={toAbsoluteUrl("/images/logo-dark-mobile.svg")} className="logo-mobile" alt="Logo" />
						</Link>
					</div>

					<nav>
						<NavLink to="/dashboard">
							{t('Header.dashboard')}

							<div className="underline-decoration" />
						</NavLink>

						{(isUserOliberal || isUsersAcOrCp) ? null : (
							<NavLink to="/report/reportDenakop">
								{t('Header.reports')}

								<div className="underline-decoration" />
							</NavLink>	
						)}
						{/* <NavLink to="/ads">
								{t('Header.adsTxt')}

								<div className="underline-decoration" />
						</NavLink> */}
						

						{/* <NavLink to="/ads">
							{t('Header.adsTxt')}

							<div className="underline-decoration" />
						</NavLink> */}
					</nav>
				</div>

				<div className="language-notification-user">
					<CustomSelect
						value={i18n.language}
						variant="outlined"
						textColor="white"
						onChange={(event) => changeLocale(event.target.value)}
					>
						{getUserLocaleTypes().map((obj, i) => {
							return <MenuItem key={`locales-${i}`} value={obj.value}>{t(obj.translateKey)}</MenuItem>;
						})}
					</CustomSelect>

					<Link to="/my-notifications" className="round-button">
						<img src={toAbsoluteUrl("/icons/notification-icon.svg")} />

						{/* <div className="d-flex justify-content-center align-items-center top-right-floating-round">
									<span>
										8
									</span>
								</div> */}
					</Link>

					<div className="vertical-separator" />

					{

					//DESCOMENTAR CASO QUEIRA LOGOUT NO HEADER
					
					 <CustomMenu
						renderButtonContent={renderButtonContent}
						className="secondary-type"
						onChange={(event) => changeLocale(event.target.value)}
						menuCustomWidth={185}
						
					>
						{/* <MenuItem onClick={() => history.push(`${indexPage()}/edit/${user.id}`)}>{'My account'}</MenuItem> */}
						{/* <MenuItem onClick={() => history.push(`/logout`)}>{'Logout'}</MenuItem> */}
					</CustomMenu> }
				</div>
			</div>
		</div>
	);
}