import React, { useState } from "react";
import { Header } from "../header/Header";
import { Footer } from "../footer/Footer";
import './layout.scss';
import { Sidebar } from "../sidebar/Sidebar";
import { ChatBalloon } from "../chat-balloon/ChatBalloon";

export default function Layout(props) {
	const [isSidebarOpen, setIsSidebarOpen] = useState(false);

	return (
		<div onClick={() => {if (isSidebarOpen) setIsSidebarOpen(false)}} className="layout-container">
			<Header isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen} />
		
			<Sidebar isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen}/>

			<div className="page-container">
				<div className="page-container-without-max-width-container">
					<hr className="layout-hr" />

					{props.children}
				</div>
			</div>

			{/* <ChatBalloon /> */}

			<Footer />
		</div>
	);
}