export default {
	header: {
		self: {},
		items: [
			{
			},
		]
	},
	aside: {
		self: {},
		items: [
			{
				title: "dashboard",
				root: true,
				icon: "fas fa-tachometer-alt",
				page: "dashboard",
				bullet: "dot"
			},
			{
				title: "reports",
				root: true,
				bullet: "dot",
				icon: "fas fa-file-alt",
				submenu: [
					{
						title: "reportsDenakop",
						bullet: "dot",
						page: "report/reportDenakop"
					},
					{
						title: "reportsData",
						bullet: "dot",
						page: "report/report01"
					},
					{
						title: "reportsOrders",
						bullet: "dot",
						page: "report/report02"
					},
					{
						title: "reportsCurrency",
						bullet: "dot",
						page: "report/report03"
					},
					{
						title: "reportsDataPerWebsite",
						bullet: "dot",
						page: "report/report04"
					},
					{
						title: "reportsFinancial",
						bullet: "dot",
						page: "report/report05"
					},

				]
			},
			{
				title: "publishers",
				root: true,
				bullet: "dot",
				icon: "fas fa-users",
				submenu: [
					{
						title: "publishersAccounts",
						bullet: "dot",
						page: "account"
					},
					{
						title: "publishersUsers",
						bullet: "dot",
						page: "user"
					},
					{
						title: "publishersVisitLogs",
						bullet: "dot",
						page: "visit-log"
					},
				]
			},
			{
				title: "formats",
				root: true,
				bullet: "dot",
				icon: "fas fa-border-all",
				submenu: [
					{
						title: "formatsFormats",
						bullet: "dot",
						page: "format"
					},
					{
						title: "formatsAutoAdRequests",
						bullet: "dot",
						page: "auto-ad-request"
					},
					{
						title: "formatsAds",
						bullet: "dot",
						page: "ads"
					},
					{
						title: "formatsTags",
						bullet: "dot",
						page: "tag"
					},
				]
			},
			{
				title: "adNetworks",
				root: true,
				bullet: "dot",
				icon: "fas fa-chart-bar",
				submenu: [
					{
						title: "adNetworksTecnologies",
						bullet: "dot",
						page: "ad-network-category"
					},
					{
						title: "adNetworksOrdersCampaigns",
						bullet: "dot",
						page: "order-campaign"
					},
					{
						title: "adNetworksOrders",
						bullet: "dot",
						page: "orders"
					},
					{
						title: "adNetworksAdNetworks",
						bullet: "dot",
						page: "ad-networks"
					},
					{
						title: "adNetworksType",
						bullet: "dot",
						page: "ad-network-type"
					},
					{
						title: "adNetworksWebsite",
						bullet: "dot",
						page: "website"
					},
					{
						title: "adNetworksRevenueShare",
						bullet: "dot",
						page: "revenue-share"
					},
					{
						title: "adNetworksApiLogs",
						bullet: "dot",
						page: "api-log"
					},
				]
			},
			// {
			// 	title: "systemLogs",
			// 	root: true,
			// 	bullet: "dot",
			// 	icon: "fas fa-database",
			// 	submenu: [
			// 		{
			// 			title: "systemLogsLogin",
			// 			bullet: "dot",
			// 			page: "system-log/logLogin"
			// 		},
			// 		{
			// 			title: "systemLogsAccounts",
			// 			bullet: "dot",
			// 			page: "system-log/logAccount"
			// 		},
			// 		{
			// 			title: "systemLogsFormats",
			// 			bullet: "dot",
			// 			page: "system-log/logFormat"
			// 		},
			// 		{
			// 			title: "systemLogsUsers",
			// 			bullet: "dot",
			// 			page: "system-log/logUser"
			// 		},
			// 		{
			// 			title: "systemLogsWebsites",
			// 			bullet: "dot",
			// 			page: "system-log/logWebsite"
			// 		},
			// 		{
			// 			title: "systemLogsAccessGroups",
			// 			bullet: "dot",
			// 			page: "system-log/logAccessGroup"
			// 		},
			// 		{
			// 			title: "systemLogsAccountManagers",
			// 			bullet: "dot",
			// 			page: "system-log/logAccountManager"
			// 		},
			// 	]
			// },
			{
				title: "currencyExchange",
				root: true,
				icon: "fas fa-money-bill",
				page: "currency-exchange",
				bullet: "dot"
			},
			{
				title: "sentNotifications",
				root: true,
				icon: "fas fa-bell",
				page: "notifications-sent",
				bullet: "dot"
			},
			{
				title: "myNotifications",
				root: true,
				icon: "fas fa-envelope-open",
				page: "my-notifications",
				bullet: "dot"
			},
			{
				title: "addData",
				root: true,
				icon: "fas fa-server",
				page: "ad-network-report/new",
				bullet: "dot"
			},
			{
				title: "accessGroups",
				root: true,
				icon: "fas fa-layer-group",
				page: "group",
				bullet: "dot"
			},
			{
				title: "maintenance",
				root: true,
				icon: "fas fa-wrench",
				page: "maintenance",
				bullet: "dot"
			},
		]
	}
};
